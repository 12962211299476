import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import idiaimg from "./assets/images/carouselimages/ideaimage.jpg";
import prototypesimg from "./assets/images/carouselimages/deliveryimage.jpg";
import testingimg from "./assets/images/carouselimages/testingimage.jpg";
import deliveryimg from "./assets/images/carouselimages/deliveryimage.jpg";

const Services = () => {
  return (
    <Container>
      <h4 className="AlignCenter">
        <b>Easy 4 Steps Customization for your Machine</b>
      </h4>
      <Row>
        <Col sm="6" md="6" lg="3" className="AlignCenter">
          <Image
            src={idiaimg}
            alt="brand image"
            width="243px"
            className="zoom image-center"
          />
          <h4>
            <b>IDEA</b>
          </h4>
          <p>
            Our team get's the deep understanding of the specific application.
          </p>
        </Col>
        <Col sm="6" md="6" lg="3" className="AlignCenter">
          <Image
            src={prototypesimg}
            alt="brand image"
            width="243px"
            className="zoom image-center"
          />
          <h4>
            <b>PROTOTYPES</b>
          </h4>
          <p>
            Based on the learning from the ideation stage, prototypes are
            developed to validate the idea.
          </p>
        </Col>
        <Col sm="6" md="6" lg="3" className="AlignCenter">
          <Image
            src={testingimg}
            alt="brand image"
            width="243px"
            className="zoom image-center"
          />
          <h4>
            <b>TESTING</b>
          </h4>
          <p>
            The prototype is then lab and field test to check the desired
            results.
          </p>
        </Col>
        <Col sm="6" md="6" lg="3" className="AlignCenter">
          <Image
            src={deliveryimg}
            alt="brand image"
            width="243px"
            className="zoom image-center AlignCenter"
          />
          <h4>
            <b>DELIVERY</b>
          </h4>
          <p>
            Once the prototype has been approved by the client; our team
            gears-up for the finally delivery.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
