import React, { useState } from "react";
import "./assets/css/style.css";
import { HashLink as Link } from "react-router-hash-link";
import { Navbar, Nav } from "react-bootstrap";
import logo from "./assets/images/logo.webp";
import ScrollToTop from "./scrolltotop";
import { useHistory } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
const Header = () => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [hidemenu, setHideMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(history.location.pathname);

  const onClickProducts = () => {
    setHideMenu(hidemenu ? false : true);
    setActiveLink("/products");
    history.push("/products");
  };

  return (
    <ScrollToTop>
      <Navbar
        expand="lg"
        expanded={expanded}
        id="navbar"
        fixed="top"
        className=" HeaderBg"
      >
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="logo" width="140" height="93" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto justify-content-end" variant="underline">
            <Link
              to="/"
              className={
                activeLink === "/" ? "nav-link current" : "nav-link line"
              }
              onClick={() => {
                setExpanded(false);
                setActiveLink("/");
              }}
            >
              Home
            </Link>
            <div
              className={
                activeLink === "/products"
                  ? "menudropdown current"
                  : "menudropdown"
              }
            >
              <Link
                // to="/products"
                to={{
                  pathname: "/products",
                  state: {
                    name: "Products",
                    state: "initial",
                  },
                }}
                className="nav-link"
                onClick={() => onClickProducts()}
              >
                Products
                <i className="fa fa-caret-down ProductDropdown"></i>
              </Link>
              <div className="menudropdown-content link-container DropdownContent">
                <ul>
                  <li>
                    <Link
                      //
                      to={{
                        pathname: "/products",
                        state: {
                          name: "Digital Panel Meter",
                          state: "first",
                        },
                      }}
                      className="DropdownLink"
                      onClick={() => setExpanded(false)}
                    >
                      Digital Panel Meter
                    </Link>
                  </li>
                  <NavDropdown.Divider />
                  <li>
                    <Link
                      to={{
                        pathname: "/products",
                        state: {
                          name: "Power Energy Meters",
                          state: "second",
                        },
                      }}
                      className="DropdownLink"
                      onClick={() => setExpanded(false)}
                    >
                      Power Energy Meters
                    </Link>
                  </li>
                  <NavDropdown.Divider />
                  <li>
                    <Link
                      to={{
                        pathname: "/products",
                        state: {
                          name: "Automatic Transfer Switch",
                          state: "fourth",
                        },
                      }}
                      className="DropdownLink"
                      onClick={() => setExpanded(false)}
                    >
                      Automatic Transfer Switch
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Link
              to="/about"
              className={
                activeLink === "/about" ? "nav-link current" : "nav-link"
              }
              onClick={() => {
                setExpanded(false);
                setActiveLink("/about");
              }}
            >
              About
            </Link>
            <Link
              to="/contact"
              className={
                activeLink === "/contact" ? "nav-link current" : "nav-link line"
              }
              onClick={() => {
                setExpanded(false);
                setActiveLink("/contact");
              }}
            >
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </ScrollToTop>
  );
};
export default Header;
