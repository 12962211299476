import Image from "react-bootstrap/Image";
import { Row, Col } from "react-bootstrap";
import DigitalAmpereMeter from "./digitalamperemeter";
import { useState } from "react";

export default function PowerEnergy({
  PowerEnergyMeters,
  isPower,
  isMultifunction,
  digitalDisable,
  setReadMore,
  isReadMore,
  setMultifunction,
  tabState,
  setBreadcrumbItem,
}) {
  const [readMoreData, setReadMoreData] = useState({});
  const powerSubProducts = PowerEnergyMeters.Products[0].subProducts.length;
  const readmore = (item, data) => {
    setMultifunction(false);
    setReadMoreData({
      data: data,
    });
    setReadMore(true);
    if (tabState === "second") {
      setBreadcrumbItem([
        {
          name: "Power & Energy Meter",
          state: "second",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    } else if (tabState === "mul") {
      setBreadcrumbItem([
        {
          name: "Power & Energy Meter",
          state: "second",
        },
        {
          name: "Multifunction Meters",
          state: "mul",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    }
  };

  return (
    <>
      <Row
        className="SingleTabText"
        style={{ display: digitalDisable && "none" }}
      >
        <Col sm={9}>
          <p>Showing all {powerSubProducts} results</p>
        </Col>
      </Row>

      {isMultifunction && (
        <Row className="SingleTabText">
          <Col sm={9}>
            <p>Showing all {powerSubProducts} results</p>
          </Col>
        </Row>
      )}
      <Row className="CardContainer">
        {isMultifunction &&
          PowerEnergyMeters.Products[0].subProducts.map((item) => {
            return (
              <>
                <Col className="CardBox" md="4" lg="2">
                  <div className="ProductContainer">
                    <Image
                      src={item.image}
                      alt="brand image"
                      width="100%"
                      className="ProductImage"
                    />
                    <div class="Middle">
                      <div
                        class="ReadmoreText"
                        onClick={() => readmore(item, item.moreInfo)}
                      >
                        Read more
                      </div>
                    </div>
                  </div>
                  <h5 className="CardText">{item.heading}</h5>
                </Col>
              </>
            );
          })}
        {isReadMore && <DigitalAmpereMeter data={readMoreData} />}
      </Row>
    </>
  );
}
