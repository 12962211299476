const DigitalPanelMeter = {
  name: "Digital Panel Meter",
  image: "../assets/images/productsimages/VAF96_OLD.jpg",
  Products: [
    {
      id: 1,
      heading: "Single Phase Panel Meter",
      image: "../assets/images/productsimages/digitalvoltamp.webp",
      subProducts: [
        {
          id: 1,
          heading: "1Ø Ampere Meter",
          image: "../assets/images/productsimages/1Phase_amp.jpg",
          moreInfo: {
            image: "../assets/images/productsimages/1Phase_amp.jpg",
            features_heading: "1Ø Ampere Meter",
            salient_features: [
              "1 phase 2 wire systems",
              "Accuracy of ± 0.5% of F.S",
              "0.8 inch, 4 digit 7 Segment LED display",
              "Panel mounted",
              "Supply voltage of 230V AC ±10%",
            ],
            categories: "Digital Panel Meter, Single Phase Panel Meter",
            subImage: [
              {
                image: "../assets/images/productsimages/1Phase_ampV2.jpg",
              },
              {
                image: "../assets/images/productsimages/1Phase_ampV2.jpg",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["AM 9601", "AM 4801"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 48 x 85"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92x 92", "46 x 92"],
              },
              {
                type: "Display Size",
                description: ["0.8 inch (20 mm)", "0.5 inch (14.2 mm)"],
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 4 Digits",
              },
              {
                type: "Rated Input (AC)",
                description:
                  "CT Secondary 5A AC Direct (50Ma to 5A) CT Primary 5A to 9000A AC",
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "230V AC + 10%, 5OHz",
              },
            ],
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalvolt.webp",
                title: "Digital Volt meter",
              },
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "1Ø Volt + Ampere Meter",
              },
            ],
          },
        },
        {
          id: 2,
          heading: "1Ø Volt + Ampere Meter",
          image: "../assets/images/productsimages/1Phase_volt_ampV2.jpg",
          moreInfo: {
            features_heading: "1Ø Volt + Ampere Meter",
            image: "../assets/images/productsimages/1Phase_volt_ampV2.jpg",
            salient_features: [
              "1 phase 2 wire systems",
              "Accuracy of ± 0.5% of F.S",
              "0.8 inch,Two Line 3 digit 7 Segment LED display",
              "Panel mounted",
              "Supply voltage of 230V AC ±10%",
            ],
            categories: "Digital Panel Meter,Single Phase Panel Meter",
            subImage: [
              {
                image: "../assets/images/productsimages/1Phase_volt_ampV2_4800.jpg",
              },
              {
                image: "../assets/images/productsimages/1Phase_volt_ampV2_4800.jpg",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["AVM 9600", "AVM 4800"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 48 x 85"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92 x 92", "46 x 92"],
              },
              {
                type: "Display Size",
                description: ["0.8 inch (20 mm)", "0.56 inch (14.2 mm)"],
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 3 Digit, 2 Line",
              },
              {
                type: "Rated Input (AC)",
                description:
                  "CT Secondary 5A AC Direct (50Ma to 5A) CT Primary 5A to 6000A AC",
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "230V AC + 10%, 50Hz",
              },
            ],
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalvolt.webp",
                title: "Digital Volt meter",
              },
              {
                image: "../assets/images/productsimages/demoimage.webp",
                title: "1Ø Ampere Meter",
              },
            ],
          },
        },
        {
          id: 3,
          heading: "DC Volt Meter",
          image: "../assets/images/productsimages/VM48DC.jpg",
          moreInfo: {
            features_heading: "DC Volt Meter",
            image: "../assets/images/productsimages/VM48DC.jpg",
            salient_features: [
              "1 phase 2 wire systems",
              "Accuracy of ± 0.5% of F.S",
              "0.8 inch, 4 digit 7 Segment LED display",
              "Panel mounted",
              "Supply voltage of 230V AC ±10%",
            ],
            categories: "Digital Panel Meter, Single Phase Panel Meter",
            subImage: [
              {
                image: "../assets/images/productsimages/VM96DC_F.jpg",
              },
              {
                image: "../assets/images/productsimages/VM96DC_F.jpg",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["VM 9601", "VM 4801"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 48 x 85"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92 x 92", "46 x 92"],
              },
              {
                type: "Display Size",
                description: ["0.8 inch (20 mm)", "0.5 inch (14.2 mm)"],
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 4 Digit",
              },
              {
                type: "Rated Input (AC)",
                description: "30V to 500V AC",
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "230V AC + 10%, 50Hz",
              },
            ],
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "digital Ampere  meter",
              },
              {
                image: "../assets/images/productsimages/digitalvolt.webp",
                title: "digital volt meter",
              },
            ],
          },
        },
        {
          id: 4,
          heading: "1Ø Volt Meter",
          image: "../assets/images/productsimages/VM4801_F.jpg",
          moreInfo: {
            image: "../assets/images/productsimages/VM4801_F.jpg",
            features_heading: "1Ø Volt Meter",
            salient_features: [
              "1 phase 2 wire systems",
              "Accuracy of ± 0.5% of F.S",
              "0.8 inch, 4 digit 7 Segment LED display",
              "Panel mounted",
              "Supply voltage of 230V AC ±10%",
            ],
            categories: "Digital Panel Meter, Single Phase Panel Meter",
            subImage: [
              {
                image: "../assets/images/productsimages/VM9601_F.jpg",
              },
              {
                image: "../assets/images/productsimages/VM9601_F.jpg",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["AM 9601", "AM 4801"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 48 x 85"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92x 92", "46 x 92"],
              },
              {
                type: "Display Size",
                description: ["0.8 inch (20 mm)", "0.5 inch (14.2 mm)"],
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 4 Digits",
              },
              {
                type: "Rated Input (AC)",
                description:
                  "CT Secondary 5A AC Direct (50Ma to 5A) CT Primary 5A to 9000A AC",
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "230V AC + 10%, 5OHz",
              },
            ],
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalvolt.webp",
                title: "Digital Volt meter",
              },
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "1Ø Volt + Ampere Meter",
              },
            ],
          },
        },
      ],
    },
    {
      id: 2,
      heading: "Three Phase Panel Meters",
      image: "../assets/images/productsimages/VAF96_OLD.jpg",
      subProducts: [
        {
          id: 1,
          heading: "3Ø VAF Meter",
          image: "../assets/images/productsimages/VAF96_OLD.jpg",
          moreInfo: {
            features_heading: "3Ø VAF Meter",
            image: "../assets/images/productsimages/VAF96_OLD.jpg",
            categories: "Three Phase Panel Meters",
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "digital Ampere meter",
              },
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF 93 Inner Cat",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["VAF 9600", "MFM 9600E"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 96 x 40"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92 x 92", "92 x 92"],
              },
              {
                type: "Display Size",
                description: ["0.56 inch (14.2 mm)", "0.56 inch (14.2 mm)"],
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 3 Digit, 3 Line",
              },
              {
                type: "Electric Connection",
                description:
                  "3Ø 4 Wire / 3Ø 3 Wire / 1Ø 2 Wire (Field Selectable)",
              },
              {
                type: "Rated Input",
                description: [
                  <>
                    Voltage : 30 to 300V AC (L-N)/ 50 to 500V AC (L-L)
                    <br />
                    CT Secondary 5A/1A (50mA to 5A)
                    <br />
                    CT Primary 5A to 6000A (Field Selectable)
                  </>,
                  "",
                ],
              },
              {
                type: "Parameters",
                description: [
                  <div className="d-flex">
                    <span style={{ listStyle: "none" }}>
                      <li>Voltage (L-L)</li>
                      <li>Voltage (L-N)</li>
                      <li>Line Current (L1, L2 & L3)</li>
                      <li>Average Current (L1, L2 & L3)</li>
                      <li>Average Voltage (L-N)</li>
                      <li>Average Voltage (L-L)</li>
                    </span>
                    <ul style={{ listStyle: "none" }}>
                      <li>Frequncy</li>
                      <li>ON Hour & RUN Hour</li>
                      <li>RPM</li>
                    </ul>
                  </div>,
                  <div className="d-flex">
                    <span style={{ listStyle: "none" }}>
                      <li>Voltage (L-L)</li>
                      <li>Voltage (L-N)</li>
                      <li>Line Current (L1, L2 & L3)</li>
                      <li>Average Current (L1, L2 & L3)</li>
                      <li>Average Voltage (L-N)</li>
                      <li>Average Voltage (L-L)</li>
                    </span>
                    <ul style={{ listStyle: "none" }}>
                      <li>Frequncy</li>
                      <li>ON Hour & RUN Hour</li>
                      <li>RPM</li>
                      <li>Power Factor (PF)</li>
                      <li>kw</li>
                      <li>Phase Sequence</li>
                    </ul>
                  </div>,
                ],
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "100-270V AC, 50/60Hz",
              },
            ],
          },
        },
        {
          id: 2,
          heading: "3Ø Ampere Meter",
          image: "../assets/images/productsimages/3Phase_ampV2.jpg",
          moreInfo: {
            features_heading: "3Ø Ampere Meter",
            image: "../assets/images/productsimages/3Phase_ampV2.jpg",
            categories: "Three Phase Panel Meters",
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "1Ø digital Ampere meter",
              },
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF 93 Inner Cat",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["AM 9603", "AM 9603E"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 96 x 40"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92 x 92", "92 x 92"],
              },
              {
                type: "Display Size",
                description: ["0.8 inch (20 mm)", "0.8 inch (20 mm)"],
              },
              {
                type: "Display Type",
                description: ["Seven Segment LED, 3 Digit, 3 Line", "4 Digit"],
              },
              {
                type: "Rated Input",
                description: [
                  <>
                    CT Secondary 5A(50mA to 5A)
                    <br />
                    CT Primary 5A to 6000 AC
                  </>,
                  "",
                ],
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: ["100-270V AC 50/60Hz", "230V AC ± 10%, 50Hz"],
              },
            ],
          },
        },
        {
          id: 3,
          heading: "Frequency Meter",
          image: "../assets/images/productsimages/FM_V2.jpg",
          moreInfo: {
            features_heading: "Frequency Meter",
            image: "../assets/images/productsimages/FM_V2.jpgp",
            categories: "Three Phase Panel Meters",
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "digital Ampere meter",
              },
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF 93 Inner Cat",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: "HZ 9600",
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: "96 x 96 x 40",
              },
              {
                type: "Panel Cutout (mm)",
                description: "92 x 92",
              },
              {
                type: "Display Size",
                description: "0.8 inch (20 mm)",
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 3 Digit, 3 Line",
              },
              {
                type: "Electric Connection",
                description: "3Ø 4 Wire / 1Ø 2 Wire",
              },
              {
                type: "Voltage",
                description: "30 to 300V AC (L-N) / 50 to 520V AC (L-L)",
              },
              {
                type: "Current",
                description: "Primary : 6000A, Secondary : 5A",
              },
              {
                type: "Measuring Range",
                description: "40 to 60Hz",
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Auxiliary Supply",
                description: "100 to 270V AC, 50/60Hz",
              },
            ],
          },
        },
        {
          id: 4,
          heading: "Earth Leakage Relay",
          image: "../assets/images/productsimages/ELR96_F.jpg",
          moreInfo: {
            features_heading: "Earth Leakage Relay",
            image: "../assets/images/productsimages/ELR96_F.jpg",
            categories: "Three Phase Panel Meters",
            subImage: [
              {
                image: "../assets/images/productsimages/cbct.webp",
              },
            ],
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "digital Ampere meter",
              },
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF 93 Inner Cat",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: "ELR 96",
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: "96 x 96 x 40",
              },
              {
                type: "Panel Cutout (mm)",
                description: "92 x 92",
              },
              {
                type: "Display Size",
                description: "Upper 0.8 inch, Lower 0.56 inch (14.2 mm)",
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 4 Digit, 2 Line",
              },
              {
                type: "Electric Connection",
                description: "30mA to 3A AC via external CBCT",
              },
              {
                type: "Rated Input",
                description: "30mA to 3.000 A AC",
              },
              {
                type: "Parameters",
                description: "1 Relay, 1 C/O 5A 230V AC (resistive load)",
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "100 to 270V AC, 50/60Hz",
              },
              {
                type: "Note",
                description: "RS-485 MODBUS is available in model ERC 96",
              },
            ],
          },
        },
        {
          id: 5,
          heading: "Multi - Function Meter",
          image: "../assets/images/productsimages/MFM96_F.jpg",
          moreInfo: {
            features_heading: "Multi - Function Meter",
            image: "../assets/images/productsimages/MFM96_F.jpg",
            stock: "Coming Soon",
            categories: "Three Phase Panel Meters",
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "digital Ampere meter",
              },
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF 93 Inner Cat",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["MFM 93", "MFM 93E"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 96 x 40"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92 x 92", "92 x 92"],
              },
              {
                type: "Display Size",
                description: "0.56 inch (14.2 mm)",
              },
              {
                type: "Display Type",
                description: "Seven Segment LED, 3 Digit, 3 Line",
              },
              {
                type: "Electric Connection",
                description:
                  "3Ø 4 Wire / 3Ø 3 Wire / 1Ø 2 Wire (Field Selectable)",
              },
              {
                type: "Rated Input",
                description: [
                  <>
                    Voltage : 30 to 300V AC (L-N)/ 50 to 500V AC (L-L)
                    <br />
                    CT Secondary 5A/1A (50mA to 5A)
                    <br />
                    CT Primary 5A to 6000A (Field Selectable)
                  </>,
                  "",
                ],
              },
              {
                type: "Parameters",
                description: [
                  <div className="d-flex">
                    <span style={{ listStyle: "none" }}>
                      <li>Voltage (L-L)</li>
                      <li>Voltage (L-N)</li>
                      <li>Line Current (L1, L2 & L3)</li>
                      <li>Average Current (L1, L2 & L3)</li>
                      <li>Average Voltage (L-N)</li>
                      <li>Average Voltage (L-L)</li>
                    </span>
                    <ul style={{ listStyle: "none" }}>
                      <li>Frequncy</li>
                      <li>ON Hour & RUN Hour</li>
                      <li>RPM</li>
                    </ul>
                  </div>,
                  <div className="d-flex">
                    <span style={{ listStyle: "none" }}>
                      <li>Voltage (L-L)</li>
                      <li>Voltage (L-N)</li>
                      <li>Line Current (L1, L2 & L3)</li>
                      <li>Average Current (L1, L2 & L3)</li>
                      <li>Average Voltage (L-N)</li>
                      <li>Average Voltage (L-L)</li>
                    </span>
                    <ul style={{ listStyle: "none" }}>
                      <li>Frequncy</li>
                      <li>ON Hour & RUN Hour</li>
                      <li>RPM</li>
                      <li>Power Factor (PF)</li>
                      <li>kw</li>
                      <li>Phase Sequence</li>
                    </ul>
                  </div>,
                ],
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "100 to 270V AC, 50/60Hz",
              },
            ],
          },
        },
        {
          id: 6,
          heading: "3Ø Volt Meter",
          image: "../assets/images/productsimages/3Phase_VM9603_F.jpg",
          moreInfo: {
            features_heading: "3Ø Volt Meter",
            image: "../assets/images/productsimages/3Phase_VM9603_F.jpg",
            categories: "Three Phase Panel Meters",
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "1Ø digital Ampere meter",
              },
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF 93 Inner Cat",
              },
            ],
            Specifications: [
              
            ],
          },
        },
      ],
    },
  ],
};
const PowerEnergyMeters = {
  name: "Power & Energy Meters",
  image: "../assets/images/productsimages/energy_mV2.jpg",
  Products: [
    {
      id: 1,
      heading: "3Ø Power & Energy Meter",
      image: "../assets/images/productsimages/energy_mV2.jpg",
      subProducts: [
        {
          id: 1,
          heading: "3Ø Power & Energy Meter",
          image: "../assets/images/productsimages/energy_mV2.jpg",
          moreInfo: {
            features_heading: "3Ø Power & Energy Meter",
            stock: "Coming Soon",
            image: "../assets/images/productsimages/energy_mV2.jpg",
            categories: "Power & Energy Meters",
            otherImage: [
              {
                image: "../assets/images/productsimages/digitalpanelmeter.webp",
                title: "VAF93 Inner Cat",
              },
              {
                image: "../assets/images/productsimages/digitalamp.webp",
                title: "Digital Ampere meter",
              },
            ],
            Specifications: [
              {
                type: "Model",
                description: ["KWH 93", "DUAL KWH 93"],
              },
              {
                type: "Dimension (HxWxD) (mm)",
                description: ["96 x 96 x 40", "96 x 96 x 40"],
              },
              {
                type: "Panel Cutout (mm)",
                description: ["92 x 92", "92 x 92"],
              },
              {
                type: "Display Size",
                description: "0.56 inch (14.2 mm)",
              },
              {
                type: "Electric Connection",
                description: [
                  "3Ø 4 Wire / 3Ø 3 Wire / 1Ø 2 Wire (Field Selectable)",
                  "AMF 420",
                ],
              },
              {
                type: "Rated Input",
                description: [
                  <>
                    Voltage : 30 to 300V AC (L-N) / 50 to 500VAC (L-L) <br />
                    CT Secondary 5A / 1A (50mA to 5A)
                    <br />
                    CT Primary 5A to 6000 AC (Field Selectable)
                  </>,
                  "",
                ],
              },
              {
                type: "Parameters",
                description: ["Single Source KWH", "Dual Source KWH"],
              },
              {
                type: "Accuracy",
                description: "Class 1.0",
              },
              {
                type: "Power Supply",
                description: "100-270V AC 50/60Hz",
              },
            ],
          },
        },
      ],
    },
  ],
};

const ATS = {
  name: "Automatic Transfer Switch",
  image: "../assets/images/productsimages/ATSV2.JPEG",
  Products: [
    {
      id: 1,
      heading: "Automatic Transfer Switch with Inbuilt Controller-4 Pole",
      image: "../assets/images/productsimages/ATSV2.JPEG",
      subProducts: [
        {
          id: 1,
          heading: "Automatic Transfer Switch with Inbuilt Controller-4 Pole",
          image: "../assets/images/productsimages/ATSV2.JPEG",
          moreInfo: {
            image: "../assets/images/productsimages/ATSV2.JPEG",
            features_heading:
              "Automatic Transfer Switch with Inbuilt Controller-4 Pole",
            salient_features: [
              "Motorized changeover with inbuilt micro-processer based ATS Controller",
              "Made of operations-Auto/Manual/Remote/Cloud connectivity",
              "Protection against under/Over voltage, Under/Over Frequency and Phase Sequence-S1",
              "Operational WIFI Communication and Cloud connectivity for |OT Applications",
              "Automatic DG start/stop operations during main's failure",
              "Universal Auxilary supply 12 V to 24V DC",
              "Source 1 & 2 indications output",
              "Fire fighting DG Start/Stop",
            ],
            categories: "Automatic Transfer Switch",
            subImage: [
              {
                image: "../assets/images/productsimages/ATS_Wide.webp",
              },
            ],
          },
        },
      ],
    },
  ],
};

export { DigitalPanelMeter, PowerEnergyMeters, ATS };
