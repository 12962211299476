import Image from "react-bootstrap/Image";
import { Row, Col } from "react-bootstrap";
import DigitalAmpereMeter from "./digitalamperemeter";
import { useState } from "react";

export default function Digital({
  DigitalPanelMeter,
  isSingle,
  isDigitalMeter,
  isDigitalthree,
  digitalDisable,
  isReadMore,
  setReadMore,
  setDigitalthree,
  setSingle,
  setBreadcrumbItem,
  ATSMeter,
  isATS,
  setIsATS,
  setDigitalPanelMeter,
  tabState,
}) {
  const [readMoreData, setReadMoreData] = useState({});

  const readmore = (item, data) => {
    if (!isReadMore) {
      setReadMore(true);
      setReadMoreData({
        data: data,
      });
    }
    setSingle(false);
    setDigitalthree(false);
    setIsATS(false);
    setDigitalPanelMeter(false);
    if (tabState === "first") {
      setBreadcrumbItem([
        {
          name: "Digital Panel Meter",
          state: "first",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    } else if (tabState === "digitalsingal") {
      setBreadcrumbItem([
        {
          name: "Digital Panel Meter",
          state: "first",
        },
        {
          name: "Single Phase Panel Meter",
          state: "digitalsingal",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    } else if (tabState === "digitalthree") {
      setBreadcrumbItem([
        {
          name: "Digital Panel Meter",
          state: "first",
        },
        {
          name: "Three Phase Panel Meter",
          state: "digitalthree",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    } else if (tabState === "fourth") {
      setBreadcrumbItem([
        {
          name: "Automatic Transfer Switch",
          state: "fourth",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    }
  };

  const singleSubProducts = DigitalPanelMeter?.Products[0].subProducts.length;
  const threePhaseSubProducts =
    DigitalPanelMeter?.Products[1].subProducts.length;
  const digitalSubProducts = singleSubProducts + threePhaseSubProducts;

  return (
    <>
      {isDigitalMeter && !isSingle && (
        <Row className="SingleTabText">
          <p style={{ marginLeft: "15px" }}>
            Digital Panel Meters are available in 4 Digit LED display. Available
            in 4 different sizes to meet various application needs.
          </p>
          <Col sm={9}>
            <p>Showing all {digitalSubProducts} results</p>
          </Col>
        </Row>
      )}
      {isSingle && !isDigitalMeter && (
        <Row className="SingleTabText">
          <Col sm={9}>
            <p>Showing all {singleSubProducts} results</p>
          </Col>
        </Row>
      )}

      {isDigitalthree && !isDigitalMeter && (
        <Row className="SingleTabText">
          <Col sm={9}>
            <p>Showing all {threePhaseSubProducts} results</p>
          </Col>
        </Row>
      )}
      <Row className="CardContainer">
        {isSingle &&
          DigitalPanelMeter.Products[0].subProducts.map((item) => {
            return (
              <Col className="CardBox" md="4" lg="2">
                <div className="ProductContainer">
                  <Image
                    src={item.image}
                    alt="brand image"
                    width="100%"
                    className="ProductImage"
                  />
                  <div class="Middle">
                    <div
                      class="ReadmoreText"
                      onClick={() => readmore(item, item.moreInfo)}
                    >
                      Read more
                    </div>
                  </div>
                </div>
                <h6 className="CardText">{item.heading}</h6>
              </Col>
            );
          })}

        {isReadMore && <DigitalAmpereMeter data={readMoreData} />}

        {isDigitalthree &&
          DigitalPanelMeter?.Products[1].subProducts.map((item) => {
            return (
              <Col className="CardBox" md="4" lg="2">
                <div className="ProductContainer">
                  <Image
                    src={item.image}
                    alt="brand image"
                    width="100%"
                    className="ProductImage"
                  />
                  <div class="Middle">
                    <div
                      class="ReadmoreText"
                      onClick={() => readmore(item, item.moreInfo)}
                    >
                      Read more
                    </div>
                  </div>
                </div>
                <h6 className="CardText">{item.heading}</h6>
              </Col>
            );
          })}

        {isATS &&
          ATSMeter.Products[0].subProducts.map((item) => {
            return (
              <Col className="CardBox" md="4" lg="2">
                <div className="ProductContainer">
                  <Image
                    src={item.image}
                    alt="brand image"
                    width="100%"
                    className="ProductImage"
                  />
                  <div class="Middle">
                    <div
                      class="ReadmoreText"
                      onClick={() => readmore(item, item.moreInfo)}
                    >
                      Read more
                    </div>
                  </div>
                </div>
                <h6 className="CardText">{item.heading}</h6>
              </Col>
            );
          })}
      </Row>
    </>
  );
}
