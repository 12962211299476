import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Form from "react-bootstrap/Form";

export default function ContactUs() {
  const information = {
    Name: "",
    Email: "",
    Message: "",
  };
  const [data, setData] = useState(information);
  const [error, setError] = useState({ name: "", email: "", message: "" });
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const formRef = useRef(null);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.Name === "" || data.Name === undefined || data.Name === null) {
      setError({ name: "Name is Required" });
    } else if (
      data.Email === "" ||
      data.Email === undefined ||
      data.Email === null
    ) {
      setError({ email: "Email is Required" });
    } else if (
      data.Message === "" ||
      data.Message === undefined ||
      data.Message === null
    ) {
      setError({ message: "Message is Required" });
    } else {
      setShowLoader(true);
      fetch(
        "https://script.google.com/macros/s/AKfycbyAcfwCUB_yor6jA1zDRiZU0wllJ-O0dTf8mtH9xL76guJi39rCBDGsII5V3bgGtdmm/exec",
        {
          method: "POST",
          body: new FormData(formRef.current),
        }
      )
        .then((res) => {
          setError({ name: "", email: "", message: "" });
          setSuccessMessage(
            "Thank you for contacting us,Your message has been successfully sent"
          );
          setTimeout(() => setSuccessMessage(""), 5000);
          setData(information);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }
  };
  return (
    <div>
      <p style={{ marginLeft: "10px", marginRight: "10px", marginTop: "20px" }}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.504860473081!2d77.45969097507697!3d13.003489887314819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3ba59c015a35%3A0x7bd38e3a514bac8d!2sKachohalli%20Industrial%20Area%2C%20Kachohalli%20Lake%20Road!5e0!3m2!1sen!2sin!4v1704441334268!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "1px solid black" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </p>

      <Row className="ContactContainer">
        <Col sm={4} md={4} lg={4}>
          <h3>Get Social</h3>
          <div className="GetSocialBox">
            <Link to="#">
              <i class="fa fa-facebook Icon FBIcon"></i>
            </Link>
            <Link to="#">
              <i class="fa fa-twitter Icon TwtitterIcon"></i>
            </Link>
            <Link to="#">
              <i class="fa fa-instagram Icon InstIcon"></i>
            </Link>
            <Link to="#">
              <i class="fa fa-google-plus Icon GIcon"></i>
            </Link>
          </div>
          <h3 style={{ marginBottom: "40px" }}>Contact Info</h3>
          <div className="FlexItem">
            <span>
              <i class="fa fa-home HomeIcon circle"></i>
            </span>

            <p className="ContactInfoText">
              NO 54 Ass No 4/1 , 1st Floor Kachohalli Industrial Area Dasanpura
              Hobli, Banglore-560091 Karnataka
            </p>
          </div>
          <div className="FlexItem">
            <i class="fa fa-phone HomeIcon circle"></i>
            <p
              className="ContactInfoText HomeText"
              style={{ marginTop: "15px" }}
            >
              +91-80 28016867
            </p>
          </div>
          <div className="FlexItem" style={{ marginTop: "10px" }}>
            <i className="fa fa-mobile HomeIcon circle"></i>
            <p
              className="ContactInfoText HomeText"
              style={{ marginTop: "15px" }}
            >
              +917975760124, +919741446867, +917892540406
            </p>
          </div>
          <div className="FlexItem" style={{ marginTop: "10px" }}>
            <i class="fa fa-envelope HomeIcon circle"></i>
            <p
              className="ContactInfoText HomeText"
              style={{
                marginTop: "15px",
                display: "block",
                wordBreak: "break-all",
              }}
            >
              {" "}
              sales@powellsindia.com{" "}
            </p>
          </div>
        </Col>
        <Col sm={4} md={8} lg={8} style={{ marginTop: "50px" }}>
          <h3>Get in Touch with Us</h3>

          <Form className="InputIcon" ref={formRef} onSubmit={handleSubmit}>
            <i class="fa fa-user Icons "></i>
            <input
              class="InputField form-control"
              type="text"
              placeholder="Your Name"
              value={data.Name}
              name="Name"
              onChange={handleInputChange}
            />
            {error && <p className="ErrorMessages">{error.name}</p>}
            <br />
            <i class="fa fa-envelope Icons"></i>
            <input
              class="InputField form-control"
              type="email"
              placeholder="Your Email"
              value={data.Email}
              name="Email"
              onChange={handleInputChange}
            />
            {error && <p className="ErrorMessages">{error.email}</p>}
            <br />
            <i class="fa fa-pencil Icons"></i>
            <input
              class="InputField MassageBox form-control"
              type="text"
              placeholder="Your Message"
              value={data.Message}
              name="Message"
              onChange={handleInputChange}
            />
            {error && <p className="ErrorMessage">{error.message}</p>}
            <button className="SubmitButton">
              {showLoader ? "Sending..." : "Submit Message"}
            </button>
          </Form>
          {successMessage && (
            <div className="alert-box">
              {" "}
              <p className="SuccessMessage">{successMessage}</p>{" "}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
