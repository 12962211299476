import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Row, Col } from "react-bootstrap";
import PowerEnergy from "./powerenergy";
import { HashLink as Link } from "react-router-hash-link";
import Digital from "./digital";
import React, { useEffect, useState } from "react";
import ProductsDefault from "./productsdefault";
import "./assets/css/style.css";
import { useLocation } from "react-router-dom";
import { DigitalPanelMeter, PowerEnergyMeters, ATS } from "./data/products";

export default function Product() {
  const location = useLocation();
  const { state, name } = location.state || {};
  const [digitalPanelMeter, setDigitalPanelMeter] = useState(true);
  const [single, setSingle] = useState(true);
  const [power, setPower] = useState(true);
  const [multifunction, setMultifunction] = useState(true);
  const [digitalthree, setDigitalthree] = useState(true);
  const [digitalDisable] = useState("false");
  const [readMore, setReadMore] = useState(false);
  const [isTemperature, setIsTemperature] = useState(false);
  const [isATS, setIsATS] = useState(false);
  const [tabState, setTabState] = useState(state);
  const [breadcrumbItem, setBreadcrumbItem] = useState([
    {
      name: "Products",
      state: "initial",
    },
  ]);
  useEffect(() => {
    setReadMore(false);
    setSingle(false);
    setPower(false);
    setMultifunction(false);
    setDigitalthree(false);
    setIsTemperature(false);
    setIsATS(false);
    if (state === "third") {
      setIsTemperature(true);
      setTabState(state);
      setBreadcrumbItem([
        {
          name: name,
          state: "",
        },
      ]);
    } else if (state === "initial") {
      setBreadcrumbItem([
        {
          name: name,
          state: "",
        },
      ]);
      setTabState(state);
    } else if (state === "first") {
      setBreadcrumbItem([
        {
          name: name,
          state: "",
        },
      ]);
      setTabState(state);
      setDigitalthree(true);
      setSingle(true);
    } else if (state === "second") {
      setBreadcrumbItem([
        {
          name: name,
          state: "",
        },
      ]);
      setTabState(state);
      setPower(true);
      setMultifunction(true);
    } else if (state === "fourth") {
      setBreadcrumbItem([
        {
          name: name,
          state: "",
        },
      ]);
      setIsATS(true);
      setTabState(state);
    }
  }, [name, state]);

  const handleSelect = (eventKey, e) => {
    e.preventDefault();
    setTabState(eventKey);
    setReadMore(false);
    setSingle(false);
    setPower(false);
    setMultifunction(false);
    setDigitalthree(false);
    setIsTemperature(false);
    setIsATS(false);
    if (eventKey === "first") {
      setBreadcrumbItem([
        {
          name: "Digital Panel Meter",
          state: "",
        },
      ]);
      setDigitalthree(true);
      setSingle(true);
    } else if (eventKey === "digitalsingal") {
      setBreadcrumbItem([
        {
          name: "Digital Panel Meter",
          state: "first",
        },
        {
          name: "Single Phase Panel Meter",
          state: "",
        },
      ]);
      setSingle(true);
    } else if (eventKey === "digitalthree") {
      setBreadcrumbItem([
        {
          name: "Digital Panel Meter",
          state: "first",
        },
        {
          name: "Three Phase Panel Meter",
          state: "",
        },
      ]);
      setDigitalthree(true);
    } else if (eventKey === "second") {
      setBreadcrumbItem([
        {
          name: "Power & Energy Meter",
          state: "",
        },
      ]);
      setMultifunction(true);
    } else if (eventKey === "mul") {
      setBreadcrumbItem([
        {
          name: "Power & Energy Meter",
          state: "second",
        },
        {
          name: "Multifunction Meters",
          state: "",
        },
      ]);
      setMultifunction(true);
    }
    // else if(eventKey === "third"){
    //   setBreadcrumbItem([{
    //     name : "Temperature Controllers",
    //     state:""
    //   }])
    //   setIsTemperature(true)
    // }
    else if (eventKey === "fourth") {
      setBreadcrumbItem([
        {
          name: "Automatic Transfer Switch",
          state: "",
        },
      ]);
      setIsATS(true);
    }
  };

  // const tempSubProducts = TemperatureController.Products[0].subProducts.length;
  const powerSubProducts = PowerEnergyMeters.Products[0].subProducts.length;
  const singleSubProducts = DigitalPanelMeter.Products[0].subProducts.length;
  const threePhaseSubProducts =
    DigitalPanelMeter.Products[1].subProducts.length;
  const totalDigitalProducts = singleSubProducts + threePhaseSubProducts;
  const totalATSProducts = ATS.Products[0].subProducts.length;

  return (
    <Tab.Container
      id="myTabs"
      defaultActiveKey="initial"
      onSelect={handleSelect}
    >
      <Row className="ProductHeaderBox">
        <Col sm={12}>
          <h1>
            {breadcrumbItem && breadcrumbItem[0]?.name
              ? breadcrumbItem[0]?.name
              : "Products"}
          </h1>
        </Col>
        <Col sm={12}>
          <Breadcrumb className="ProductB">
            <Breadcrumb.Item className="TextCW">
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            {breadcrumbItem &&
              breadcrumbItem.map((item) => {
                return (
                  <Breadcrumb.Item active>
                    {item.state === "" ? (
                      <p className="TextCW d-flex">{item.name}</p>
                    ) : (
                      <Nav.Link
                        className="TextCW"
                        eventKey={item.state !== "" && item.state}
                      >
                        {item.name}
                      </Nav.Link>
                    )}
                  </Breadcrumb.Item>
                );
              })}
          </Breadcrumb>
        </Col>
      </Row>

      {/* Tab Sidebar */}

      <Row className="ProductTab">
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item className="TabText">
              <Nav.Link
                eventKey="first"
                active={tabState === "first" ? true : false}
              >
                Digital Panel Meter{" "}
                <span className="TextCB">({totalDigitalProducts})</span>
              </Nav.Link>
              <hr className="TabHr" />
              <ul>
                <li>
                  <Nav.Link
                    eventKey="digitalsingal"
                    active={tabState === "digitalsingal" ? true : false}
                  >
                    Single Phase Panel Meter{" "}
                    <span className="TextCB">({singleSubProducts})</span>
                  </Nav.Link>{" "}
                </li>
                <hr className="TabHr" />
                <li>
                  {" "}
                  <Nav.Link
                    eventKey="digitalthree"
                    active={tabState === "digitalthree" ? true : false}
                  >
                    Three Phase Panel Meters{""}
                    <span className="TextCB">({threePhaseSubProducts})</span>
                  </Nav.Link>
                </li>
                <hr className="TabHr" />
              </ul>
            </Nav.Item>
            <Nav.Item className="TabText">
              <Nav.Link
                eventKey="second"
                active={tabState === "second" ? true : false}
              >
                Power & Energy Meters{" "}
                <span className="TextCB">({powerSubProducts})</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="TabText">
              <hr className="TabHr" />

              <Nav.Link
                eventKey="fourth"
                active={tabState === "fourth" ? true : false}
              >
                Automatic Transfer Switch{" "}
                <span className="TextCB">({totalATSProducts})</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>

        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane active={tabState === "initial"}>
              <ProductsDefault
                DigitalPanelMeter={DigitalPanelMeter}
                isDigitalMeter={digitalPanelMeter}
                PowerEnergyMeters={PowerEnergyMeters}
                setIsATS={setIsATS}
                ATSMeter={ATS}
              />
            </Tab.Pane>
            <Tab.Pane active={tabState === "first"}>
              <Digital
                DigitalPanelMeter={DigitalPanelMeter}
                isDigitalMeter={digitalPanelMeter}
                isSingle={single}
                isDigitalthree={digitalthree}
                isReadMore={readMore}
                tabState={tabState}
                setReadMore={setReadMore}
                setDigitalthree={setDigitalthree}
                setSingle={setSingle}
                setBreadcrumbItem={setBreadcrumbItem}
                setIsATS={setIsATS}
                setDigitalPanelMeter={setDigitalPanelMeter}
              />
            </Tab.Pane>
            <Tab.Pane active={tabState === "digitalsingal"}>
              <Digital
                DigitalPanelMeter={DigitalPanelMeter}
                isSingle={single}
                isReadMore={readMore}
                setReadMore={setReadMore}
                setDigitalthree={setDigitalthree}
                setSingle={setSingle}
                setBreadcrumbItem={setBreadcrumbItem}
                setIsATS={setIsATS}
                tabState={tabState}
                setDigitalPanelMeter={setDigitalPanelMeter}
              />
            </Tab.Pane>
            <Tab.Pane active={tabState === "digitalthree"}>
              <Digital
                DigitalPanelMeter={DigitalPanelMeter}
                isDigitalthree={digitalthree}
                digitalDisable={digitalDisable}
                isReadMore={readMore}
                setReadMore={setReadMore}
                setDigitalthree={setDigitalthree}
                setSingle={setSingle}
                setBreadcrumbItem={setBreadcrumbItem}
                setIsATS={setIsATS}
                tabState={tabState}
                setDigitalPanelMeter={setDigitalPanelMeter}
              />
            </Tab.Pane>
            <Tab.Pane active={tabState === "second"}>
              <PowerEnergy
                PowerEnergyMeters={PowerEnergyMeters}
                isMultifunction={multifunction}
                isDigitalthree={digitalthree}
                digitalDisable={digitalDisable}
                isReadMore={readMore}
                setReadMore={setReadMore}
                setDigitalthree={setDigitalthree}
                setSingle={setSingle}
                setPower={setPower}
                tabState={tabState}
                setMultifunction={setMultifunction}
                setBreadcrumbItem={setBreadcrumbItem}
              />
            </Tab.Pane>
            <Tab.Pane active={tabState === "fourth"}>
              <Digital
                ATSMeter={ATS}
                isATS={isATS}
                isDigitalthree={digitalthree}
                digitalDisable={digitalDisable}
                isReadMore={readMore}
                setReadMore={setReadMore}
                setDigitalthree={setDigitalthree}
                setSingle={setSingle}
                setBreadcrumbItem={setBreadcrumbItem}
                setIsATS={setIsATS}
                tabState={tabState}
                setDigitalPanelMeter={setDigitalPanelMeter}
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
