import { Col, Container, Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Image from "react-bootstrap/Image";
import { Tab, Tabs } from "react-bootstrap";
import Table from "react-bootstrap/Table";

export default function DigitalAmpereMeter({ data }) {
  return (
    <Container>
      <Row>
        <Col lg="5">
          {data.data?.stock && (
            <h3 className="chip">{data && data?.data?.stock}</h3>
          )}
          <Image src={data?.data?.image} width="100%" />

          {data?.data?.subImage &&
            data.data.subImage.map((item) => {
              return (
                <Image
                  src={item.image}
                  style={{ width: "120px", margin: "5px" }}
                />
              );
            })}
        </Col>
        <Col lg="7">
          <h4>{data && data?.data?.features_heading}</h4>
          <ul className="ListItems">
            {data?.data?.salient_features &&
              data.data.salient_features.map((item) => {
                return <li>{item}</li>;
              })}

            <p>
              Categories:{" "}
              <span className="Categories">
                {data && data?.data?.categories}
              </span>
            </p>
          </ul>
          <div>
            <Link to="#" className=" ProductsIcon ">
              <i
                class="fa fa-facebook PFBIcon"
                style={{ marginTop: "30px" }}
              ></i>
            </Link>
            <Link to="#" className=" ProductsIcon ">
              <i class="fa fa-twitter PTIcon" style={{ marginTop: "30px" }}></i>
            </Link>
            <Link to="#" className="ProductsIcon">
              <i class="fa fa-google-plus " style={{ marginTop: "30px" }}></i>
            </Link>
            <Link to="#" className="ProductsIcon ">
              <i
                class="fa fa-pinterest PTIcon"
                style={{ marginTop: "30px" }}
              ></i>
            </Link>
            <Link to="#" className="ProductsIcon">
              <i class="fa fa-tumblr PFBIcon" style={{ marginTop: "30px" }}></i>
            </Link>
            <Link to="#" className="ProductsIcon">
              <i
                class="fa fa-linkedin PTIcon"
                style={{ marginTop: "30px" }}
              ></i>
            </Link>
          </div>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3 CenterTab"
      >
        <Tab eventKey="home" title="Technical Specifications">
          <div style={{ overflowX: "auto" }}>
            <Table striped="columns">
              <tbody>
                {data?.data?.Specifications &&
                  data?.data?.Specifications.map((item) => {
                    return (
                      <tr>
                        <th>{item.type}</th>
                        {typeof item.description === "object" ? (
                          item.description.map((item, index, array) => {
                            return (
                              <td
                                style={{
                                  borderRight: `${
                                    array[1] === ""
                                      ? "none"
                                      : "1px solid lightgray"
                                  }`,
                                }}
                              >
                                {item}
                              </td>
                            );
                          })
                        ) : (
                          <td colSpan={2}>{item.description}</td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Downloads">
          <div className="FlexItem" style={{ alignItems : "center" }}>
            <button className="SubmitButton">Download</button>
            <h6 className="UserText">User Manual</h6>
          </div>
          {data?.data?.otherImage?.length > 0 && <h2>Related products</h2>}
        </Tab>
      </Tabs>
      <Row>
        {data?.data?.otherImage &&
          data?.data?.otherImage.map((item) => {
            return (
              <Col sm={2}>
                <Image src={item.image} alt="brand image" width="100%" />
                <h5 className="Address">{item.title}</h5>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}
