import React from "react";
import { Carousel } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useState } from "react";
import carousel1 from "./assets/images/carouselimages/slide1.png";
import carousel2 from "./assets/images/carouselimages/slide2.png";
import carousel3 from "./assets/images/carouselimages/slide3.png";
import carousel4 from "./assets/images/carouselimages/slide4.png";
import carousel5 from "./assets/images/carouselimages/slide5.png";

function Slider() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel fade activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item className="bgImage">
        <div style={{ display: "flex" }}>
          <div className="CarouselTextBox">
            <Carousel.Caption className="CarouselText">
              <h1 className="CTextHeading">
                VAF 9603
                <br /> 3Ø VAF METER
              </h1>
              <p className="CText">
                with 45mm back depth it makes the simmest VAF meter equiped with
                advanced features like <b>RPM, On Hour, Run Hour. </b>Easy to
                program with Bright RED & WITHE display option.
              </p>

              <div style={{ display: "flex" }}>
                <div>
                  <p className="CText">
                    Know more about the product
                    <br />
                    <b>Download Datasheet</b>{" "}
                  </p>
                </div>
                <div>
                </div>
              </div>
            </Carousel.Caption>
          </div>
          <Image className="CarouselImage" src={carousel1} alt="1st slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item className="bgImage" interval={900}>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="CarouselTextBox">
            <Carousel.Caption className="CarouselText">
              <h1 className="CTextHeading">
                AVM 4800
                <br /> 1Ø VOLT + AMPERE METER
              </h1>
              <p className="CText">
                now monitor three phase voltage simultaneously with, brigher
                disply Available in <b>RED & WHITE </b> color.
              </p>

              <div style={{ display: "flex" }}>
                <div>
                  <p className="CText">
                    Know more about the product
                    <br />
                    <b>Download Datasheet</b>{" "}
                  </p>
                </div>
                <div>
                </div>
              </div>
            </Carousel.Caption>
          </div>
          <Image className="CarouselImage" src={carousel2} alt="1st slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item className="bgImage" interval={900}>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="CarouselTextBox">
            <Carousel.Caption className="CarouselText">
              <h1 className="CTextHeading">
                AM 9601
                <br />
                1Ø AMPERE METER
              </h1>
              <p className="CText">
                now monitor current with{" "}
                <b>bigger 0.8 inch digit size, brighter display </b>Available in{" "}
                <b>RED & WHITE </b> color.
              </p>

              <div style={{ display: "flex" }}>
                <div>
                  <p className="CText">
                    Know more about the product
                    <br />
                    <b>Download Datasheet</b>{" "}
                  </p>
                </div>
                <div>
                </div>
              </div>
            </Carousel.Caption>
          </div>
          <Image className="CarouselImage" src={carousel3} alt="1st slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item className="bgImage" interval={900}>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="CarouselTextBox">
            <Carousel.Caption className="CarouselText">
              <h1 className="CTextHeading">
                VM 9603
                <br /> 3Ø VOLT METER
              </h1>
              <p className="CText">
                now monitor three phase voltage simultaneously with, bighter
                display
                <br />
                Available in<b> RED & WHITE </b>color.
              </p>

              <div style={{ display: "flex" }}>
                <div>
                  <p className="CText">
                    Know more about the product
                    <br />
                    <b>Download Datasheet</b>{" "}
                  </p>
                </div>
                <div>
                </div>
              </div>
            </Carousel.Caption>
          </div>
          <Image className="CarouselImage" src={carousel4} alt="1st slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item className="bgImage" interval={900}>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="CarouselTextBox">
            <Carousel.Caption className="CarouselText">
              <h1 className="CTextHeading">
                VM 48.DC
                <br /> DC VOLT METER
              </h1>
              <p className="CText">
                now monitor three phase voltage simultaneously with, bighter
                display
                <br />
                 Available in<b> RED & WHITE </b>color.
              </p>

              <div style={{ display: "flex" }}>
                <div>
                  <p className="CText">
                    Know more about the product
                    <br />
                    <b>Download Datasheet</b>{" "}
                  </p>
                </div>
                <div>
                </div>
              </div>
            </Carousel.Caption>
          </div>
          <Image className="CarouselImage" src={carousel5} alt="1st slide" />
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;
