import Image from "react-bootstrap/Image";
import { Row, Col } from "react-bootstrap";
import DigitalAmpereMeter from "./digitalamperemeter";
import { useState } from "react";

export default function TemperatureControllers({
  TemperatureController,
  isReadMore,
  setReadMore,
  isTemperature,
  setIsTemperature,
  tabState,
  setBreadcrumbItem,
}) {
  const [readMoreData, setReadMoreData] = useState({});
  const tempSubProducts = TemperatureController.Products[0].subProducts.length;
  const readmore = (item, data) => {
    setReadMore(true);
    setReadMoreData({
      data: data,
    });
    setIsTemperature(false);
    if (tabState === "third") {
      setBreadcrumbItem([
        {
          name: "Temperature Controllers",
          state: "third",
        },
        {
          name: item.heading,
          state: "",
        },
      ]);
    }
  };

  return (
    <>
      <p>Showing all {tempSubProducts} results</p>

      <Row className="CardContainer">
        {isTemperature &&
          TemperatureController.Products[0].subProducts.map((item) => {
            return (
              <Col md="4" lg="2" className="CardBox">
                <div className="ProductContainer">
                  <Image
                    src={item.image}
                    alt="brand image"
                    width="100%"
                    className="ProductImage "
                  />
                  <div class="Middle">
                    <div
                      class="ReadmoreText"
                      onClick={() => readmore(item, item.moreInfo)}
                    >
                      Read more
                    </div>
                  </div>
                </div>
                <h5 className="CardText">{item.heading}</h5>
              </Col>
            );
          })}
        {isReadMore && <DigitalAmpereMeter data={readMoreData} />}
      </Row>
    </>
  );
}
