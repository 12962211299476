import React from "react";
import logo from "./assets/images/logo.webp";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  function topFunction() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      duration: 1000,
    });
  }

  const onButtonClick = () => {
    const pdfUrl = "Powells India Products Catalogue.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;

    link.download = "./Powells India Products Catalogue.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="FooterBox">
      <Row className="FooterC">
        <Col sm={12} md={6} lg={4}>
          <Link to="/">
            <img src={logo} alt="logo" className="FLogo"></img>
          </Link>
          {/* <h2 className="FText">A Brand by Cosfi Electric Pvt Ltd</h2> */}
          <div className="FlexItem LoremText">
            <span class="avatar">
              {" "}
              <i class="fa fa-map-marker FLocationIcon"></i>
            </span>

            <p className="Address">
              NO 54 Ass No 4/1 , 1st Floor Kachohalli Industrial Area Dasanpura
              Hobli, Banglore-560091 Karnataka
            </p>
          </div>
          <div className="FlexItem">
            <i class="fa fa-phone FPhoneIcon" circle></i>
            <p className="Address HomeText">+91-80 28016867</p>
          </div>
          <div className="FlexItem">
            <i className="fa fa-mobile FPhoneIcon"></i>
            <p className="Address HomeText">
              +917975760124, +919741446867, +917892540406
            </p>
          </div>
          <div className="FlexItem">
            <i class="fa fa-envelope FPhoneIcon" circle></i>
            <p className="Address HomeText" style={{ wordBreak: "break-all" }}>
              sales@powellsindia.com
              <br />
              support@powellsindia.com
            </p>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <h2 className="FLinkHeader">Quick Links</h2>
          <Link to="/" className="FLink" onClick={topFunction}>
            Home
          </Link>
          <hr className="FooterHr" />
          <Link
            to={{
              pathname: "/products",
              state: {
                name: "Products",
                state: "initial",
              },
            }}
            className="FLink"
            onClick={topFunction}
          >
            Products
          </Link>
          <hr className="FooterHr" />
          <ul>
            <li>
              <Link
                to={{
                  pathname: "/products",
                  state: {
                    name: "Digital Panel Meter",
                    state: "first",
                  },
                }}
                className="FLink"
                onClick={topFunction}
              >
                Digital Panel Meter
              </Link>
            </li>
            <hr className="FooterHr" />
            <li>
              <Link
                to={{
                  pathname: "/products",
                  state: {
                    name: "Temperature Controllers",
                    state: "third",
                  },
                }}
                className="FLink"
                onClick={topFunction}
              >
                Temperature Controllers
              </Link>
            </li>{" "}
            <hr className="FooterHr" />
            <li>
              <Link
                to={{
                  pathname: "/products",
                  state: {
                    name: "Automatic Transfer Switch",
                    state: "fourth",
                  },
                }}
                className="FLink"
                onClick={topFunction}
              >
                Automatic Transfer Switch
              </Link>
            </li>{" "}
            <hr className="FooterHr" />
          </ul>
          <Link to="/about" className="FLink" onClick={topFunction}>
            About
          </Link>
          <hr className="FooterHr" />
          <Link to="/contact" className="FLink" onClick={topFunction}>
            Contact
          </Link>
          <hr className="FooterHr" />
        </Col>
        <Col sm={12} md={6} lg={4}>
          <h2 className="FLinkHeader">Downloads</h2>
          <p className="FLink" onClick={onButtonClick}>
            Product Catalogue
          </p>
          <hr className="FooterHr" />
          <Link
            to={{
              pathname: "/products",
              state: {
                name: "Products",
                state: "initial",
              },
            }}
            className="FLink"
          >
            Product Manual
          </Link>
        </Col>
      </Row>
      <Row className="FooterB" style={{ marginTop: "10px" }}>
        <Col sm={9}>
          <p className="FCopyright">
            Copyright {currentYear} Powells India Corporation | All Rights
            Reserved
          </p>
        </Col>
        <Col sm={3}>
          <div>
            <Link to="#">
              <i class="fa fa-facebook LinkIcons TextCW"></i>
            </Link>
            <Link to="#">
              <i class="fa fa-twitter LinkIcons TextCW"></i>
            </Link>
            <Link to="#">
              <i class="fa fa-instagram LinkIcons TextCW"></i>
            </Link>
            <Link to="#">
              <i class="fa fa-linkedin LinkIcons TextCW "></i>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}
