import Image from "react-bootstrap/Image";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ProductsDefault({
  DigitalPanelMeter,
  PowerEnergyMeters,
  TemperatureController,
  ATSMeter,
}) {
  return (
    <>
      <Row className="CardContainer">
        <Col md="4" lg="2" className="CardBox">
          <Link
            to={{
              pathname: "/products",
              state: {
                name: "Digital Panel Meter",
                state: "first",
              },
            }}
          >
            <Image
              src={DigitalPanelMeter.image}
              alt="brand image"
              width="100%"
              className="DefaultProductImage"
            />
          </Link>
          <h5 className="DefaultCardText">{DigitalPanelMeter.name}</h5>
        </Col>
        <Col md="4" lg="2" className="CardBox">
          <Link
            to={{
              pathname: "/products",
              state: {
                name: "Power Energy Meters",
                state: "second",
              },
            }}
          >
            <Image
              src={PowerEnergyMeters.image}
              alt="brand image"
              width="100%"
              className="DefaultProductImage"
            />
          </Link>
          <h5 className="DefaultCardText">{PowerEnergyMeters.name}</h5>
        </Col>
        <Col md="4" lg="2" className="CardBox">
          <Link
            to={{
              pathname: "/products",
              state: {
                name: "Automatic Transfer Switch",
                state: "fourth",
              },
            }}
          >
            <Image
              src={ATSMeter.image}
              alt="brand image"
              width="100%"
              className="DefaultProductImage"
            />
          </Link>
          <h5 className="DefaultCardText">{ATSMeter.name}</h5>
        </Col>
      </Row>
    </>
  );
}
