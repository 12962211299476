import React from "react";
import { Row, Col } from "react-bootstrap";

export default function About() {
  return (
    <div>
      <div className="BackgroundImage">
        <h1 className="AboutText">About Us</h1>
      </div>
      <div className="AboutContainer">
        <h3 className="AboutContainerHeader">
          ABOUT POWELLS INDIA CORPORATION
        </h3>

        <p className="Text">
          Energy and resources are often exploited to help us live our
          fulfilling lives. Unfortunately, a lot of this energy is wasted by way
          of simple neglect.
        </p>

        <p className="Text">
        </p>

        <Row className="OureVisitionContainer">
          <Col sm={4}>
            <h4 className="OurVisionText">
              <b>OUR OBJECTIVE</b>
            </h4>

            <p className="Text">
              We are a couple of enthusiastic entrepreneurs who understand the
              absolute importance of energy in our day to day lives and have
              taken it up as our personal motto in life to ensure proper
              conservation of energy..
            </p>

            <p className="Text">
              With collective experience in energy management and process
              automation, our objective is to make a significant contribution by
              focusing on industries and commercial establishments to reduce
              waste and minimizing the loss.
            </p>
          </Col>
          <Col sm={4}>
            <h4 className="OurVisionText">
              <b>OUR VISION</b>
            </h4>
            <p className="Text">
              At Powells India Corporation, We envision a future where our
              innovative Electric meters play a pivotal role in transforming the
              global energy landscape. Guided by our commitment to
              sustainability, efficiency, and technological advancement, we
              strive to be at the forefront of the smart energy revolution.
            </p>
            <p className="Text">
              Our vision is to empower individuals, businesses, and communities
              with cutting-edge metering solutions that not only accurately
              measure and monitor energy consumption but also facilitate
              intelligent energy management. We aspire to create a world where
              our meters contribute significantly to the reduction of carbon
              footprint, promoting a cleaner and more sustainable planet.
            </p>
            <p className="Text">
              As a leader in the electric meter industry, we aim to foster a
              culture of continuous innovation, pushing the boundaries of what
              is possible.Through strategic partnerships, research and
              development, and a dedicated team, we seek to develop
              next-generation smart meters that not only meet the evolving needs
              of our customers but also anticipate and shape the future of
              energy consumption.
            </p>
            <p className="Text">
              In our vision, we see a seamless integration of our meters into
              smart grids, enabling real-time data analytics, predictive
              maintenance, and the efficient distribution of electricity. By
              embracing the principles of connectivity and intelligence, we
              envision a grid that is resilient, responsive, and capable of
              supporting the growing demand for sustainable energy solutions.
            </p>
            <p className="Text">
              Ultimately, at Powells India Corporation, our vision is to be
              recognized globally as the preferred provider of electric meters,
              known for our commitment to quality, innovation, and environmental
              stewardship. We strive to contribute significantly to the creation
              of a more sustainable, and intelligent energy ecosystem for
              generations to come.``
            </p>
          </Col>
          <Col sm={4}>
            <h4 className="OurVisionText">
              <b>OUR MISSION</b>
            </h4>
            <p className="Text">
              At Sense Electric, our goal is to unlock the potential of
              businesses by creating an interconnected, interoperable business
              operation that will future-proof your ecosystem for years to come.
            </p>

            <p className="Text">
              Our company has been established for managing energy with the best
              efficiency and providing services with our expert and experienced
              staffs for industrial automation field which has a very important
              role in the ever-growing electronics sector.
            </p>

            <p className="Text">
              We aim to provide quality products for end-users by using the
              technology developed based on customer satisfaction and to put
              specific products in the industrial electronics sector on the
              local market following customer demands and expectations.
            </p>

            <p className="Text">
              We provide our customers with easily accessible products and also
              pre-sale and after-sale services thanks to distributors and dealer
              networks.
            </p>
            <h5>
              <b>Product Category</b>
            </h5>
            <ol className="Text">
              <li>Electrical Measurements</li>
              <li>Protection and Control</li>
              <li>Compensation – Power Factor Controller</li>
              <li>Process Control & Automation</li>
            </ol>
          </Col>
        </Row>
      </div>
    </div>
  );
}
