import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home";
import About from "./about";
import Header from "./header";
import Footer from "./footer";
import TopNavbar from "./topnavbar";
import Product from "./product";
import ContactUs from "./contactus";
import Digital from "./digital";
import TemperatureControllers from "./temperaturecontrollers";
import DigitalAmpereMeter from "./digitalamperemeter";

const NavBarTop = () => {
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 10 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("GoTOTop").style.display = "block";
      document.getElementById("navbar").style.boxShadow =
        "0px 15px 10px -15px gray";
    } else {
      document.getElementById("GoTOTop").style.display = "none";
      document.getElementById("navbar").style.boxShadow = "none";
    }
  }

  function topFunction() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      duration: 1000,
    });
  }

  return (
    <Router>
      <TopNavbar />
      <Header />

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/products">
          <Product />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Route path="/temperature">
          <TemperatureControllers />
        </Route>
        <Route path="/digitalam">
          <DigitalAmpereMeter />
        </Route>
        <Route path="/digital">
          <Digital />
        </Route>
      </Switch>
      <button onClick={topFunction} id="GoTOTop">
        <i class="fa fa-angle-up" aria-hidden="true"></i>
      </button>
      <Footer />
    </Router>
  );
};

export default NavBarTop;
